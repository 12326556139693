<template>
  <svg width="24" height="24" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.7562 9.19189L9.98633 32.8288" stroke="black" stroke-width="2.6411" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M33.7562 32.8288L9.98633 9.19189" stroke="black" stroke-width="2.6411" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'X'
}
</script>
