<template>
  <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#flutterwave_icon)">
      <path d="M1 7.29536C1 5.69428 1.46819 4.3273 2.45071 3.34479L4.15943 5.05351C2.25762 6.94849 3.9185 12.8402 9.33345 18.2602C14.7484 23.6803 20.6435 25.3395 22.5436 23.4411L24.2523 25.1498C21.0467 28.3554 13.7454 26.0811 7.63327 19.9673C3.39392 15.7262 1 10.9144 1 7.29536Z" fill="#009A46"/>
      <path d="M11.7755 26.6227C10.1744 26.6227 8.80747 26.1545 7.82324 25.172L9.53197 23.4633C11.4304 25.3617 17.322 23.7008 22.7421 18.2825C28.1622 12.8641 29.8196 6.97241 27.9212 5.07402L29.63 3.3653C32.8372 6.57086 30.5612 13.8722 24.4474 19.9843C20.2064 24.2305 15.3946 26.6227 11.7755 26.6227Z" fill="#FF5805"/>
      <path d="M25.0129 17.539C23.9757 14.5521 21.8569 11.3602 19.0461 8.55111C12.934 2.43729 5.63258 0.161274 2.42702 3.36855C2.19976 3.59752 2.39626 4.16311 2.86787 4.63471C3.33948 5.10632 3.90506 5.30112 4.13232 5.07386C6.03242 3.17546 11.9241 4.83634 17.3425 10.2564C19.9056 12.8195 21.8159 15.6816 22.7369 18.3301C23.5434 20.6523 23.4648 22.5251 22.525 23.4648C22.2961 23.6921 22.4943 24.2594 22.9642 24.7293C23.4341 25.1992 24.0014 25.3974 24.2303 25.1684C25.8724 23.5264 26.1509 20.8129 25.0129 17.539Z" fill="#F5AFCB"/>
      <path d="M29.6262 3.36863C27.9807 1.72313 25.2706 1.44461 21.9933 2.58433C19.0081 3.61981 15.8145 5.74034 13.0054 8.54948C6.8933 14.6616 4.61728 21.963 7.82284 25.1702C8.05181 25.3975 8.6174 25.201 9.08901 24.7311C9.56061 24.2612 9.75712 23.6939 9.52815 23.4649C7.62463 21.5665 9.29064 15.6749 14.7107 10.2548C17.2738 7.6917 20.1359 5.78135 22.7844 4.86035C25.1066 4.05554 26.9793 4.13243 27.9209 5.07223C28.1481 5.29949 28.7154 5.10299 29.1853 4.63138C29.6552 4.15977 29.8534 3.59589 29.6262 3.36863Z" fill="#FF9B00"/>
    </g>
    <defs>
      <clipPath id="flutterwave_icon">
        <rect width="31.907" height="28" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Flutterwave'
}
</script>
