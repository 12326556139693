<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.89665 4.59456C7.28242 2.20517 10.3036 0.778944 13.4751 0.545093C17.7121 0.232615 21.5032 1.54874 24.4354 4.35022C25.2474 5.126 26.448 6.59035 26.448 6.80491C26.448 6.85399 24.5063 6.86414 22.1331 6.8277C19.76 6.79115 16.5745 6.80243 15.0544 6.85275C12.0972 6.95066 11.4676 7.06471 10.3729 7.70061C8.32499 8.89051 7.07632 11.523 7.43268 13.8994L7.51266 14.4329L8.0996 13.8777C8.45777 13.5391 8.94916 13.2182 9.36046 13.0546C10.0322 12.7873 10.0549 12.7862 16.0133 12.7225C22.4682 12.6533 22.2714 12.6716 23.5714 12.0197C24.6207 11.4935 26.6461 9.71141 27.2588 8.77522L27.4433 8.4932L27.7269 9.12605C28.1831 10.1439 28.5836 11.7784 28.723 13.1905C28.9455 15.445 28.5683 17.8661 27.662 19.9996C26.4283 22.9037 23.9628 25.5241 21.0786 26.9964C16.3513 29.4095 10.5985 28.8868 6.29716 25.6534C5.56222 25.101 4.19115 23.7747 3.90349 23.3382C3.72841 23.0723 3.73958 23.0532 4.46708 22.3774C5.91237 21.0347 6.8533 20.7361 9.64417 20.7342C11.6956 20.7329 12.4091 20.627 13.4385 20.1717C14.6645 19.6294 16.0163 18.507 17.0102 17.2063C17.7811 16.1973 18.4521 15.0926 18.3723 14.9635C18.3394 14.9104 16.3591 14.8668 13.9714 14.8668C8.94059 14.8668 8.48631 14.9211 6.85578 15.717C4.89654 16.6732 3.7433 18.0801 2.55927 20.9585C2.4494 21.2254 2.42244 21.1953 2.054 20.3944C1.57502 19.3535 1.24935 18.3614 0.998348 17.1794C0.725917 15.8962 0.726594 13.1032 0.99959 11.8255C1.62139 8.91589 2.8405 6.65375 4.89665 4.59456Z" fill="#7535FD"/>
  </svg>
</template>

<script>
export default {
  name: 'Fincra'
}
</script>
