const matches = [
  // Tap VS Periculum
  {
  player1: {
      name: 'Tella AbdulRasheed',
      company: 'Touch and Pay',
      icon: 'tap',
      goals: 2,
      played: false,
      winner: false,
  },
      player2: {
      name: 'Timothy Ojo',
      company: 'Periculum',
      icon: 'periculum',
      goals: 1,
      played: false,
      winner: false,
  }
  },
  // Flutterwave vs CredPal
  {
    player1: {
        name: 'Oluwaseye Akinyemi',
        company: 'Flutterwave',
        icon: 'flutterwave',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Fagbuyi Damilare',
        company: 'CredPal',
        icon: 'credpal',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // IdentityPass vs Patricia
  {
    player1: {
        name: 'Mayowa Poroku',
        company: 'IdentityPass',
        icon: 'identity-pass',
        goals: 0,
        played: false,
        winner: false,
    },
    player2: {
        name: 'Abduljalal isa bichi',
        company: 'Patricia',
        icon: 'patricia',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Grey vs GetEquity
  {
    player1: {
        name: 'Aghedo Joseph Femi',
        company: 'Grey',
        icon: 'grey',
        goals: 0,
        played: false,
        winner: false,
    },
    player2: {
        name: 'Temitayo Kara',
        company: 'GetEquity',
        icon: 'get-equity',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Sycamore vs Trove
  {
    player1: {
    name: 'Kingsley Makinde',
        company: 'Sycamore',
        icon: 'sycamore',
        goals: 1,
        played: false,
        winner: false,
    },
    player2: {
        name: 'Chidubem Ovbiebo',
        company: 'Trove Finance',
        icon: 'trove',
        goals: 2,
        played: false,
        winner: false,
    }
  },
  // Cowrywise VS Allawee 
  {
    player1: {
        name: 'Feyisayo Sonubi',
        company: 'Cowrywise',
        icon: 'cowrywise',
        goals: 1,
        played: false,
        winner: false,
    },
    player2: {
        name: 'Emmanuel Ezeh',
        company: 'Allawee',
        icon: 'allawee',
        goals: 3,
        played: false,
        winner: false,
    }
  },
  // Youverify vs Bundle
  {
    player1: {
        name: 'Emmanuel Agwu',
        company: 'Youverify',
        icon: 'youverify',
        goals: 1,
        played: false,
        winner: false,
    },
    player2: {
        name: 'Yemi Otitoju',
        company: 'Bundle Africa',
        icon: 'bundle',
        goals: 4,
        played: false,
        winner: false,
    }
  },
  // Kuda vs softcom
  {
    player1: {
        name: 'Afolabi Emmanuel',
        company: 'Kuda',
        icon: 'kuda',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Ekene Cyril Obiorah',
        company: 'Softcom',
        icon: 'softcom',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Arca vs ZoroPAy
  {
    player1: {
        name: 'Olaniyi Oluwole',
        company: 'Arca',
        icon: 'arca',
        goals: 6,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Kachukwu Michael Esenwa',
        company: 'Zoropay',
        icon: 'zoropay',
        goals: 1,
        played: false,
        winner: false,
    }
  },
  // FinCra vs Mono
  {
    player1: {
        name: 'Olumide Adaramoye',
        company: 'Fincra',
        icon: 'fincra',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Moses Idowu',
        company: 'Mono',
        icon: 'mono',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Clan vs Softcom
  {
    player1: {
        name: 'Jude Francis',
        company: 'Clan',
        icon: 'credit-clan',
        goals: 2,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Yeshua Ezeigbo',
        company: 'Softcom',
        icon: 'softcom',
        goals: 3,
        played: false,
        winner: false,
    }
  },
  // Allawee vs FIncra
  {
    player1: {
        name: 'Eronss Okojie',
        company: 'Allawee',
        icon: 'allawee',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Tolulope Abiodun',
        company: 'Fincra',
        icon: 'fincra',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Clan vs Cowrywise
  {
    player1: {
        name: 'Seun Suleman',
        company: 'Clan',
        icon: 'credit-clan',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Dara Fakoya',
        company: 'Cowrywise',
        icon: 'cowrywise',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Patricia vs Zoropay
  {
    player1: {
        name: 'Abduljalal isa bichi',
        company: 'Patricia',
        icon: 'patricia',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Paul Ikele',
        company: 'Zoropay',
        icon: 'zoropay',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Flutterwave vs Trove
  {
    player1: {
        name: 'Adedoyin Ademola',
        company: 'Flutterwave',
        icon: 'flutterwave',
        goals: 6,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Oluwatobi Osho',
        company: 'Trove Finance',
        icon: 'trove',
        goals: 2,
        played: false,
        winner: false,
    }
  },
  // Arca vs Bundle
  {
    player1: {
        name: 'Olawale Olaseinde',
        company: 'Arca',
        icon: 'arca',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Bayo Ogundele',
        company: 'Bundle Africa',
        icon: 'bundle',
        goals: 5,
        played: false,
        winner: false,
    }
  },
  // GetEquity vs Sycamore
  {
    player1: {
        name: 'Seni Oremodu',
        company: 'GetEquity',
        icon: 'get-equity',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Joseph Akinnitire',
        company: 'Sycamore',
        icon: 'sycamore',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // YouVerify vs Kuda
  {
    player1: {
        name: 'Hakeem Akiode',
        company: 'Youverify',
        icon: 'youverify',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Afolabi Emmanuel',
        company: 'Kuda',
        icon: 'kuda',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Periculum vs Identity Pass
  {
    player1: {
        name: 'Timothy Ojo',
        company: 'Periculum',
        icon: 'periculum',
        goals: 2,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Mayowa Poroku',
        company: 'IdentityPass',
        icon: 'identity-pass',
        goals: 1,
        played: false,
        winner: false,
    }
  },
  // Mono Vs CredPal
  {
    player1: {
        name: 'King Kenway',
        company: 'Mono',
        icon: 'mono',
        goals: 0,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Fagbuyi Damilare',
        company: 'CredPal',
        icon: 'credpal',
        goals: 0,
        played: false,
        winner: false,
    }
  },
  // Grey Vs TAP
  {
    player1: {
        name: 'Precious',
        company: 'Grey',
        icon: 'grey',
        goals: 2,
        played: false,
        winner: false,
    },
        player2: {
        name: 'Onimole-Alo Adegboyega',
        company: 'Touch and Pay',
        icon: 'tap',
        goals: 4,
        played: false,
        winner: false,
    }
  }
];

export default matches;
