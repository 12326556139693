<template>
  <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 13.5H23" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 13.5H13" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 11.5V15.5" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.5001 6.96249L10.5001 6.99999C8.97224 7.0029 7.49407 7.54287 6.32412 8.52545C5.15417 9.50802 4.36691 10.8707 4.10006 12.375V12.375L2.05006 22.8875C1.91843 23.6216 2.02475 24.3786 2.35352 25.048C2.68228 25.7175 3.2163 26.2644 3.87771 26.6091C4.53911 26.9537 5.29333 27.0781 6.03038 26.964C6.76742 26.85 7.44877 26.5034 7.97506 25.975V25.975L13.3751 20L21.5001 19.9625C23.224 19.9625 24.8773 19.2777 26.0963 18.0587C27.3152 16.8397 28.0001 15.1864 28.0001 13.4625C28.0001 11.7386 27.3152 10.0853 26.0963 8.8663C24.8773 7.64731 23.224 6.96249 21.5001 6.96249V6.96249Z" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.9 12.3375L29.95 22.8875C30.0816 23.6216 29.9753 24.3786 29.6465 25.048C29.3178 25.7175 28.7838 26.2644 28.1224 26.6091C27.4609 26.9537 26.7067 27.0781 25.9697 26.964C25.2326 26.85 24.5513 26.5034 24.025 25.975V25.975L18.625 19.975" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Controller'
}
</script>
