<template>
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#kuda_icon)">
      <path d="M19.1308 27.9811L8.08596 15.2676L14.1173 27.9811H9.93623L3.7838 15.0198L6.34164 27.9811H3.77245L1.21272 15.0009V27.9811H0.204346V-0.0812988H1.21272V13.9187L3.8084 -0.0812988H6.37002L3.76299 13.9792L10.0346 -0.0812988H14.1703L7.92326 13.9281L19.267 -0.0812988H25.7486L14.0151 14.4125L25.8035 27.9811H19.1308Z" fill="#41276D"/>
    </g>
    <defs>
      <clipPath id="kuda_icon">
        <rect width="26.0465" height="28" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Kuda'
}
</script>
