<template>
  <svg width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.718018 13.9308C0.718018 17.5458 2.22465 21.335 4.64271 23.7633C9.37496 28.5162 16.268 29.3607 21.9706 25.8368C22.4774 25.5244 23.299 24.9028 23.6973 24.5041C27.1074 21.0822 26.5246 20.1938 26.5246 15.287V9.36938C26.5246 7.47949 27.126 6.34774 27.9661 5.28196C29.0341 3.92887 30.6678 3.45328 32.4907 3.45328C36.9191 3.45328 39.7075 9.37094 36.1767 13.1036C34.9335 14.4175 32.7852 15.1348 30.8368 14.6184C30.2059 14.4504 30.2043 14.3013 29.4464 14.3013C27.9056 14.3013 27.019 16.8379 29.0945 17.6149C32.3403 18.8298 35.4977 17.9806 38.0722 15.8851C43.8616 11.1745 40.9849 0 32.1248 0C30.6322 0 28.9333 0.508574 27.9459 1.06895C25.7077 2.34038 23.9918 4.44374 23.4322 7.09963C23.0912 8.7164 23.237 15.3592 23.2385 17.505C23.24 20.0792 22.7115 21.3538 20.6112 22.7336C16.4695 25.4538 10.8568 25.2874 7.38318 21.7274C6.54925 20.8735 5.71533 19.8987 5.17903 18.7827C3.48018 15.2447 3.80724 11.0693 6.0238 7.83894C6.26096 7.49203 6.34776 7.45909 6.57871 7.16869C6.81431 6.8736 6.8546 6.7904 7.13827 6.5016C8.5054 5.10771 11.187 3.45328 13.8654 3.45328C15.3131 3.45328 15.8805 3.38735 17.3267 3.77036C18.4101 4.0576 18.2675 4.31658 19.5866 4.31658C20.7011 4.31658 20.9258 3.20056 20.9258 2.34509C20.9258 0.66868 16.5454 0.00313934 14.5955 0.00313934C12.2565 0.00313934 10.6166 0.554094 8.9766 1.21649C8.15511 1.54927 7.47463 1.96994 6.77867 2.44241C6.41285 2.69042 6.14936 2.91331 5.813 3.19114C5.61922 3.35125 5.55878 3.44072 5.37278 3.60868C4.27845 4.596 3.34688 5.63982 2.62456 6.98818C2.3719 7.46065 2.20605 7.65371 1.9875 8.19211C1.29153 9.90305 0.719568 11.5685 0.719568 13.934L0.718018 13.9308Z" fill="#274FED"/>
  </svg>
</template>

<script>
export default {
  name: 'Credpal'
}
</script>
