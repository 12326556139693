<template>
  <div class="flex w-screen">
    <div v-for="(item, index) in multipleColors" :key="index" class="p-1 w-1/5" :style="`background-color: ${item}`" />
  </div>
</template>

<script>
export default {
  computed: {
    multipleColors() {
      return ['#FD4674', '#FDAF81', '#3BBBFA', '#6D51E4', '#B740CE']
    }
  }
}
</script>
