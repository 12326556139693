<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4223 12.4588H7.75834C6.33367 12.4588 5.177 11.3022 5.177 9.87749V8.4246C5.177 6.99993 6.33367 5.84326 7.75834 5.84326H15.8268C17.2515 5.84326 18.4081 6.99993 18.4081 8.4246V12.4588H18.4223Z" fill="url(#paint0_linear_790_532)"/>
    <path d="M20.2558 23.4886H12.1873C10.7626 23.4886 9.60596 22.332 9.60596 20.9073V16.873H20.2699C21.6945 16.873 22.8512 18.0297 22.8512 19.4544V20.9073C22.8371 22.332 21.6804 23.4886 20.2558 23.4886Z" fill="url(#paint1_linear_790_532)"/>
    <path d="M22.8371 0.666504H5.23321C2.35565 0.666504 0 3.00805 0 5.89972V23.5036C0 23.9268 0.338536 24.2653 0.761707 24.2653C1.18488 24.2653 1.52342 23.9268 1.52342 23.5036V6.337C1.52342 4.03777 3.38537 2.17581 5.6846 2.17581H22.823C23.2462 2.17581 23.5847 1.83728 23.5847 1.41411C23.5988 1.00504 23.2603 0.666504 22.8371 0.666504Z" fill="url(#paint2_linear_790_532)"/>
    <path d="M27.2524 5.06787C26.8292 5.06787 26.4907 5.40641 26.4907 5.82958V22.9821C26.4907 25.2813 24.6288 27.1433 22.3295 27.1433H5.17699C4.75382 27.1433 4.41528 27.4818 4.41528 27.905C4.41528 28.3282 4.75382 28.6667 5.17699 28.6667H22.7809C25.6585 28.6667 28.0141 26.3252 28.0141 23.4335V5.82958C28.0141 5.42051 27.6756 5.06787 27.2524 5.06787Z" fill="url(#paint3_linear_790_532)"/>
    <defs>
    <linearGradient id="paint0_linear_790_532" x1="5.18391" y1="9.14242" x2="18.4247" y2="9.14242" gradientUnits="userSpaceOnUse">
    <stop offset="1.98289e-07" stop-color="#356D03"/>
    <stop offset="1" stop-color="#47A603"/>
    </linearGradient>
    <linearGradient id="paint1_linear_790_532" x1="9.59734" y1="20.1755" x2="22.8381" y2="20.1755" gradientUnits="userSpaceOnUse">
    <stop offset="5.94866e-07" stop-color="#B63239"/>
    <stop offset="1" stop-color="#E23939"/>
    </linearGradient>
    <linearGradient id="paint2_linear_790_532" x1="0.0110448" y1="12.4526" x2="23.5972" y2="12.4526" gradientUnits="userSpaceOnUse">
    <stop offset="1.98289e-07" stop-color="#356D03"/>
    <stop offset="1" stop-color="#47A603"/>
    </linearGradient>
    <linearGradient id="paint3_linear_790_532" x1="4.42485" y1="16.8665" x2="28.011" y2="16.8665" gradientUnits="userSpaceOnUse">
    <stop offset="5.94866e-07" stop-color="#B63239"/>
    <stop offset="1" stop-color="#E23939"/>
    </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Sycamore'
}
</script>
