import { createApp } from 'vue'
import LoadScript from 'vue-plugin-load-script';
import App from './App.vue'
import router from './router'
import "./assets/css/index.css";
import Cowrywise from '@/icons/Cowrywise.vue'
import Kuda from '@/icons/Kuda.vue'
import Zoropay from '@/icons/Zoropay.vue'
import Flutterwave from '@/icons/Flutterwave.vue'
import GetEquity from '@/icons/GetEquity.vue'
import Creditclan from '@/icons/Creditclan.vue'
import Arca from '@/icons/Arca.vue'
import Allawee from '@/icons/Allawee.vue'
import Softcom from '@/icons/Softcom.vue'
import Mono from '@/icons/Mono.vue'
import Fincra from '@/icons/Fincra.vue'
import Trove from '@/icons/Trove.vue'
import Tap from '@/icons/Tap.vue'
import Identitypass from '@/icons/Identitypass.vue'
import Periculum from '@/icons/Periculum.vue'
import Grey from '@/icons/Grey.vue'
import Okra from '@/icons/Okra.vue'
import Patricia from '@/icons/Patricia.vue'
import Bundle from '@/icons/Bundle.vue'
import Credpal from '@/icons/Credpal.vue'
import Youverify from '@/icons/Youverify.vue'
import Sycamore from '@/icons/Sycamore.vue'

const Vue = createApp(App);
Vue.component('cowrywise', Cowrywise);
Vue.component('kuda', Kuda);
Vue.component('zoropay', Zoropay);
Vue.component('flutterwave', Flutterwave);
Vue.component('get-equity', GetEquity);
Vue.component('credit-clan', Creditclan);
Vue.component('arca', Arca);
Vue.component('allawee', Allawee);
Vue.component('softcom', Softcom);
Vue.component('mono', Mono);
Vue.component('fincra', Fincra);
Vue.component('trove', Trove);
Vue.component('tap', Tap);
Vue.component('identity-pass', Identitypass);
Vue.component('periculum', Periculum);
Vue.component('grey', Grey);
Vue.component('okra', Okra);
Vue.component('patricia', Patricia);
Vue.component('bundle', Bundle);
Vue.component('credpal', Credpal);
Vue.component('youverify', Youverify);
Vue.component('sycamore', Sycamore);

Vue.use(LoadScript);
Vue.use(router).mount('#app')
