<template>
  <div>
    <section class="hero relative w-full bg-white pl-10 pt-8 pr-8 md:pr-0 md:pl-20 md:pt-10">
      <div class="absolute w-80 left-0 top-28 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
      <div class="hidden md:block absolute w-96 left-80 bottom-0 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
      <div class="flex">
        <router-link to="/leaderboard">
          <gradient-button text="Leaderboard">
            <div class="ml-2">
              <trophy-small />
            </div>
          </gradient-button>
        </router-link>
        <div class="mr-5" />
        <router-link to="/matches">
          <gradient-button text="Fixtures">
            <div class="ml-2">
              <controller />
            </div>
          </gradient-button>
        </router-link>
      </div>
      <div class="flex">
        <div class="w-full md:w-1/2 mt-12 md:mt-28">
          <p class="text-3xl md:text-6xl mb-2 text-dark01">TIME TO UNLEASH</p>
          <p class="text-5xl md:text-8xl text-dark01">THE BEAST</p>
          <p class="font-clash-display text-lg md:text-xl mt-3">
            <a href="https://sycamore.ng" target="_blank" class="text-pink font-semibold">Sycamore</a> is hosting Nigerian Fintechs to a FIFA contest on PS5.
            <br />
            That's how we got the name "FINFA". Get it? Fintech + FIFA 😎
          </p>
          <div class="mt-3 md:mt-16">
            <router-link to="/watch" class="w-full">
              <c-button text="WATCH LIVE" />
            </router-link>
            <div class="flex items-center font-clash-display text-sm md:text-base mt-5">
              <p>Organized by&nbsp;</p>
              <div class="w-20">
                <img src="@/assets/images/logo.png" alt="Logo">
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 absolute right-0 -bottom-10">
          <img src="@/assets/images/hero.png" alt="Hero">
        </div>
      </div>
    </section>
    <section class="mt-10">
      <multiple-colors />
    </section>
    <section class="the-what bg-dark px-10 py-10 md:px-20 md:py-40 flex flex-col md:flex-row items-center" :style="`background-image: url(${require('@/assets/images/Zap.png')})`">
      <div class="w-full md:w-3/5">
        <p class="the-what-title text-3xl md:text-5xl mb-5">NEED TO UNWIND?</p>
        <p class="font-clash-display text-white text-xl md:text-3xl">
          It's time to let off some steam.
          <br /><br />
          Yes! you, the creative geniuses behind the most innovative financial solutions in the world.
        </p>
      </div>
      <div class="hidden md:w-2/5 md:flex justify-center">
        <div style="width: 350px">
          <img src="@/assets/images/ea-color.png" class="w-full" alt="EA Color">
        </div>
      </div>
    </section>
    <section class="bg-white px-10 py-10 md:px-20 md:py-40 flex flex-col md:flex-row relative items-center">
      <div class="w-full md:w-1/2">
        <p class="text-3xl md:text-5xl mb-5">Requirements</p>
        <p class="font-clash-display text-xl md:text-4xl">
          So you think you are the best at FIFA?
          <br />
          Here's how it's going down.
        </p>
      </div>
      <div class="w-full md:w-1/2 md:pl-16 mt-5 md:mt-0">
        <div class="relative bg-purple6D text-white py-8 px-10 rounded-3xl font-clash-display mb-5">
          2 representatives from each company are required to register using the provided
          <a class="font-semibold" href="https://forms.gle/7j8LmkWui6Y7rU9V9" target="_blank">link</a>.
          <div class="absolute bottom-2 right-2">
            <controller-faded />
          </div>
        </div>
        <div class="relative bg-pink text-white py-8 px-10 rounded-3xl font-clash-display">
          Companies must be Nigerian Fintechs.
          <div class="absolute bottom-2 right-2">
            <strategy />
          </div>
        </div>
      </div>
      <div class="absolute w-96 left-0 top-20 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
    </section>
    <section class="pb-20 overflow-x-scroll">
      <div class="text-center font-clash-display mb-10">
        Fintechs that have already signed up ❤️
      </div>
      <div class="px-20 mb-8">
        <hr />
      </div>
      <div id="companies" class="overflow-x-scroll">
        <div class="flex flex-col md:flex-row justify-between items-center min-w-max">
          <div v-for="(company, index) in companies" :key="index" class="w-36 mb-14 md:mb-0">
            <img :src="require('@/assets/images/companies/' + company)" class="w-full" :alt="company">
          </div>
        </div>
      </div>
      <div class="px-20 mt-8">
        <hr />
      </div>
    </section>
    <section>
      <multiple-colors />
    </section>
    <section class="requirements bg-brown px-10 py-10 md:px-20 md:py-40 text-white" :style="`background-image: url(${require('@/assets/images/Zap.png')})`">
      <p class="text-2xl md:text-5xl mb-7">Zero financial requirements</p>
      <ul class="font-clash-display text-xl md:text-3xl">
        <li class="mb-5">✅  Participation is absolutely free.</li>
        <li>✅  No entry fees, no sponsorship required</li>
      </ul>
    </section>
    <section class="w-full flex flex-col md:flex-row relative bg-white px-10 py-10 md:px-20 md:py-40">
      <div class="w-full md:w-1/4">
        <p class="text-3xl md:text-5xl mb-3 md:mb-7">Venue:</p>
        <p class="font-clash-display text-lg md:text-2xl">
          Sycamore HQ, 53B Oduduwa Way, Off Isaac John, Ikeja GRA, Lagos.
        </p>
      </div>
      <div class="w-full md:w-3/4 pl-0 md:pl-20 mt-5 md:mt-0">
        <p class="text-3xl md:text-5xl mb-3 md:mb-7">Activity Schedule</p>
        <table class="font-clash-display text-sm md:text-base">
          <thead>
            <th>Activity</th>
            <th>Date</th>
            <th>Time</th>
          </thead>
          <tbody>
            <tr>
              <td>Qualifying Round</td>
              <td>22nd October 2022</td>
              <td>10am</td>
            </tr>
            <tr>
              <td>Final Round</td>
              <td>29th October 2022</td>
              <td>4pm</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="absolute w-72 md:w-96 left-10 top-10 md:left-40 md:top-20 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
      <div class="absolute hidden md:block w-96 right-60 -bottom-28 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
    </section>
    <section class="winning relative bg-dark px-10 py-10 md:px-20 md:pl-28 md:py-40" :style="`background-image: url(${require('@/assets/images/Zap.png')})`">
      <div class="w-full md:w-3/5">
        <p class="the-what-title text-3xl md:text-5xl mb-5">Winning Prizes</p>
        <div v-for="(prize, index) in prizes" :key="index" class="flex items-center font-clash-display text-white text-lg md:text-2xl mb-10">
          <component :is="prize.icon" v-bind="{color: prize.color}" />
          <p class="font-bold mr-7 ml-5">{{prize.position}}</p>
          <p>{{prize.value}}</p>
        </div>
      </div>
      <div class="trophy hidden md:block w-1/2 absolute right-0 top-0 bottom-0">
        <img src="@/assets/images/trophy.png" class="w-full h-full" alt="Trophy">
      </div>
    </section>
    <section class="brag text-center px-10 py-10 md:px-20 md:py-48">
      <div class="text-2xl md:text-5xl text-white">
        Ultimately though, you can't beat the bragging rights can you?
      </div>
      <div class="mt-10 flex justify-center">
        <router-link to="/watch">
          <c-button text="WATCH LIVE" />
        </router-link>
      </div>
    </section>
    <section class="flex flex-col md:flex-row relative px-10 py-10 md:px-32 md:py-32">
      <div class="w-full md:w-2/5">
        <p class="text-3xl">FAQs</p>
      </div>
      <div class="w-full md:w-3/5">
        <accordion>
          <accordion-item v-for="(faq, index) in faqs" :key="index">
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template v-slot:accordion-trigger>
              <h3 class="font-clash-display font-semibold text-sm md:text-base">{{faq.title}}</h3>
            </template>
            <!-- This slot will handle all the content that is passed to the accordion -->
            <template v-slot:accordion-content>
              <span class="font-clash-display text-sm" v-html="changeLink(faq.content)" />
            </template>
          </accordion-item>
        </accordion>
      </div>
      <div class="absolute hidden md:block w-80 md:w-96 left-10 top-10 md:left-32 md:top-28 z-0">
        <img src="@/assets/images/ea.png" class="w-full" alt="EA">
      </div>
    </section>
    <section>
      <div class="hidden md:block px-20">
        <hr>
      </div>
      <div class="text-center font-clash-display py-10 text-sm md:text-base">
        Copyright 2022
        <a href="https://sycamore.ng" target="_blank" class="text-pink">SycamoreNG</a>
        . All rights reserved.
      </div>
    </section>
  </div>
</template>

<script>
import GradientButton from '@/components/GradientButton.vue';
import MultipleColors from '@/components/MultipleColors.vue';
import CButton from '@/components/CButton.vue';
import Accordion from '@/components/Accordion.vue';
import AccordionItem from '@/components/AccordionItem.vue';
import TrophyVue from '@/components/Trophy.vue';
import TrophySmall from '@/components/TrophySmall.vue';
import Controller from '@/components/Controller.vue';
import Strategy from '@/components/Strategy.vue';
import ControllerFaded from '@/components/ControllerFaded.vue';

export default {
  name: 'Home',
  components: {
    GradientButton,
    MultipleColors,
    CButton,
    Accordion,
    AccordionItem,
    TrophySmall,
    Controller,
    Strategy,
    ControllerFaded
  },
  methods: {
    changeLink(value) {
      const newValue = value.replace('link', '<a class="text-pink" href="https://forms.gle/7j8LmkWui6Y7rU9V9" target="_blank">link</a>')
      return newValue;
    },
    scrollCompanies() {
      const companiesContainer = document.getElementById("companies");
      const companiesScrollWidth = companiesContainer.scrollWidth;

      window.addEventListener("load", () => {
        self.setInterval(() => {
          if (companiesContainer.scrollLeft !== companiesScrollWidth) {
            companiesContainer.scrollTo(companiesContainer.scrollLeft + 1, 0);
          } else {
            companiesContainer.scrollLeft = 0;
          }
        }, 15);
      });
    },
  },
  computed: {
    companies() {
      return [
        'Kuda.png',
        'Softcom.png',
        'Cowrywise.png',
        'Creditclan.png',
        'GetEquity.png',
        'zoropay.svg',
        'ARCA.png',
        'Allawee.png'
      ]
    },
    prizes() {
      return [
        {
          position: '1st place',
          value: 'N250k for each player',
          icon: TrophyVue,
          color: '#fdaf81'
        },
        {
          position: '2nd place',
          value: 'N100k for each player',
          icon: TrophyVue,
          color: '#c0c2c8'
        },
        {
          position: '3rd place',
          value: 'N50k for each player',
          icon: TrophyVue,
          color: '#493d0e'
        },
      ]
    },
    faqs() {
      return [
        {
          title: 'Any entry or participation fee?',
          content: 'None at all'
        },
        {
          title: 'Does my company have to sponsor to participate?',
          content: 'No, your company doesn\'t. You just have to nominate 2 people to represent the company.'
        },
        {
          title: 'Do representatives from my team have to be founders or executive staff?',
          content: 'No. As long as they work at the company, they can participate even if they just joined yesterday!'
        },
        {
          title: 'How do we sign up to participate?',
          content: 'Please register using this link.'
        },
        {
          title: 'When and how long is the event?',
          content: 'It\'s a 2 day event spread across 2 weekends in October (22nd and 29th). The first day is the qualifying round, and the 2nd day is the final round.'
        },
      ]
    }
  },
  mounted() {
    this.scrollCompanies();
  }
}
</script>

<style lang="scss" scoped>
.hero {
  height: 80vh;

  @media (max-width: 600px) {
    height: 50vh;
  }
}
.the-what, .requirements, .winning {
  background-blend-mode: luminosity;
  background-position: center;
  background-size: cover;
}
.the-what-title {
  background: linear-gradient(90.98deg, #37FFF7 0%, #54FFCF 48.57%, #66B6B7 104.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  // text-fill-color: transparent;
}
table {
  width: 80%;

  thead {
    th {
      padding: 30px 20px 30px 20px;
      background-color: #F9FAFA;
    }
  }

  tbody {
    tr:last-child {
      td {
        border-top: 0.5px solid #696969;
        padding-bottom: 0;
      }
    }
    td {
      text-align: center;
      padding: 30px 20px 30px 20px;
    }
  }
}
.brag {
  background: linear-gradient(99.8deg, rgba(253, 70, 116, 0.9) -18.3%, rgba(109, 81, 228, 0.9) 81.17%), url(../assets/images/brag.jpg) no-repeat center;
  background-size: cover;
}
</style>
