<template>
  <svg width="40" height="40" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path d="M46.7146 33.192H56.5492" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.1281 33.192H31.9627" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M27.0454 28.2747V38.1093" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M52.8614 17.1185L25.8162 17.2107C22.0598 17.2179 18.4255 18.5455 15.5489 20.9613C12.6724 23.3771 10.7368 26.7274 10.0807 30.426V30.426L5.04048 56.2727C4.71685 58.0777 4.97826 59.9388 5.78658 61.5847C6.5949 63.2307 7.90787 64.5754 9.53404 65.4228C11.1602 66.2702 13.0146 66.576 14.8267 66.2955C16.6389 66.0151 18.3141 65.1631 19.608 63.8638V63.8638L32.8848 49.1733L52.8614 49.0811C57.0999 49.0811 61.1648 47.3974 64.1619 44.4003C67.159 41.4032 68.8427 37.3383 68.8427 33.0998C68.8427 28.8613 67.159 24.7964 64.1619 21.7993C61.1648 18.8023 57.0999 17.1185 52.8614 17.1185V17.1185Z" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M68.5967 30.3339L73.6369 56.2727C73.9606 58.0777 73.6992 59.9388 72.8908 61.5848C72.0825 63.2307 70.7696 64.5754 69.1434 65.4228C67.5172 66.2702 65.6628 66.576 63.8507 66.2955C62.0385 66.0151 60.3634 65.1631 59.0694 63.8639V63.8639L45.7926 49.1119" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ControllerFaded'
}
</script>
