const companies = [
  {
    name: 'Kuda',
    players: [
      {
        name: 'Afolabi Emmanuel',
        email: 'damilola.afolabi@kuda.com',
      }
    ],
    icon: 'kuda',
    GP: 0,
    GW: 0,
    GF: 0,
    GA: 0,
    points: 0
  },
  {
    name: 'Zoropay',
    players: [
      {
        name: 'Kachukwu Michael Esenwa',
        email: 'michael.esenwa@gmail.com',
      },
      {
        name: 'Paul Ikele',
        email: 'paul@zoropay.com',
      },
    ],
    icon: 'zoropay',
    GP: 3,
    GW: 0,
    GF: 2,
    GA: 10,
    points: 0
  },
  {
    name: 'Cowrywise',
    players: [
      {
        name: 'Feyisayo Sonubi',
        email: 'feyisayo@cowrywise.com',
      },
      {
        name: 'Dara Fakoya',
        email: 'dara@cowrywise.com'
      }
    ],
    icon: 'cowrywise',
    GP: 3,
    GW: 1,
    GF: 6,
    GA: 10,
    points: 5
  },
  {
    name: 'Allawee',
    players: [
      {
        name: 'Emmanuel Ezeh',
        email: 'manny@allawee.com',
      },
      {
        name: 'Eronss Okojie',
        email: 'eronss@allawee.com',
      },
    ],
    icon: 'allawee',
    GP: 3,
    GW: 2,
    GF: 18,
    GA: 5,
    points: 11
  },
  {
    name: 'Softcom',
    players: [
      {
        name: 'Ekene Cyril Obiorah',
        email: 'obiorah@softcom.xyz',
      },
      {
        name: 'Yeshua Ezeigbo',
        email: 'yeshua@eyowo.com',
      }
    ],
    icon: 'softcom',
    GP: 3,
    GW: 2,
    GF: 9,
    GA: 6,
    points: 10
  },
  {
    name: 'GetEquity',
    players: [
      {
        name: 'Temitayo Kara',
        email: 'temitayo@getequity.io',
      },
      {
        name: 'Seni Oremodu',
        email: 'seni@getequity.io',
      },
    ],
    icon: 'get-equity',
    GP: 0,
    GW: 0,
    GF: 0,
    GA: 0,
    points: 0
  },
  {
    name: 'Arca',
    players: [
      {
        name: 'Olaniyi Oluwole',
        email: 'olaniyi.oluwole@arca.network',
      },
      {
        name: 'Olawale Olaseinde',
        email: 'wale.olaseinde@arca.network',
      }
    ],
    icon: 'arca',
    GP: 3,
    GW: 2,
    GF: 7,
    GA: 6,
    points: 12
  },
  {
    name: 'Clan',
    players: [
      {
        name: 'Jude Francis',
        email: 'francis.jude@creditclan.com',
      },
      {
        name: 'Seun Suleman',
        email: 'seun.suleiman@creditclan.com',
      }
    ],
    icon: 'credit-clan',
    GP: 3,
    GW: 2,
    GF: 14,
    GA: 6,
    points: 15
  },
  {
    name: 'Trove Finance',
    players: [
      {
        name: 'Chidubem Ovbiebo',
        email: 'chidubem@troveapp.co',
      },
      {
        name: 'Oluwatobi Osho',
        email: 'tobi@troveapp.co',
      },
    ],
    icon: 'trove',
    GP: 3,
    GW: 1,
    GF: 5,
    GA: 10,
    points: 4
  },
  {
    name: 'Fincra',
    players: [
      {
        name: 'Olumide Adaramoye',
        email: 'adaraolumide@gmail.com',
      },
      {
        name: 'Tolulope Abiodun',
        email: 'tolulope@fincra.com',
      },
    ],
    icon: 'fincra',
    GP: 0,
    GW: 0,
    GF: 0,
    GA: 0,
    points: 0
  },
  {
    name: 'Mono',
    players: [
      {
        name: 'Moses Idowu',
        email: 'moses@mono.co',
      },
      {
        name: 'King Kenway',
        email: 'king@mono.co',
      },
    ],
    icon: 'mono',
    GP: 3,
    GW: 0,
    GF: 5,
    GA: 11,
    points: 0
  },
  {
    name: 'IdentityPass',
    players: [
      {
        name: 'Mayowa Poroku',
        email: 'mayowa@myidentitypass.com',
      },
      {
        name: 'Michael Oredunni',
        email: 'michael@myidentitypass.com',
      },
    ],
    icon: 'identity-pass',
    GP: 3,
    GW: 0,
    GF: 2,
    GA: 7,
    points: 0
  },
  {
    name: 'Touch and Pay',
    players: [
      {
        name: 'Tella AbdulRasheed',
        email: 'rasheed@touchandpay.me',
      },
      {
        name: 'Onimole-Alo Adegboyega',
        email: 'adegboyega@touchandpay.me',
      },
    ],
    icon: 'tap',
    GP: 3,
    GW: 2,
    GF: 9,
    GA: 6,
    points: 9
  },
  {
    name: 'Periculum',
    players: [
      {
        name: 'Timothy Ojo',
        email: 'timothy.ojo@periculum.io',
      },
      {
        name: 'Caleb Chinga',
        email: 'caleb.chinga@periculum.io',
      },
    ],
    icon: 'periculum',
    GP: 3,
    GW: 1,
    GF: 3,
    GA: 4,
    points: 3
  },
  {
    name: 'Flutterwave',
    players: [
      {
        name: 'Oluwaseye Akinyemi',
        email: 'seye.akinyemi@flutterwavego.com',
      },
      {
        name: 'Adedoyin Ademola',
        email: 'adedoyin@flutterwavego.com',
      },
    ],
    icon: 'flutterwave',
    GP: 3,
    GW: 3,
    GF: 14,
    GA: 4,
    points: 19
  },
  {
    name: 'Patricia',
    players: [
      {
        name: 'Abduljalal isa bichi',
        email: 'abduljalal.bichi@roomxix.com',
      },
      {
        name: 'Jesutofunmi Onigbinde',
        email: 'jesutofunmi.onigbinde@gloverapp.co',
      },
    ],
    icon: 'patricia',
    GP: 0,
    GW: 0,
    GF: 0,
    GA: 0,
    points: 0
  },
  {
    name: 'Grey',
    players: [
      {
        name: 'Aghedo Joseph Femi',
        email: 'femi@grey.co',
      },
      {
        name: 'Precious',
        email: 'precious@grey.co',
      },
    ],
    icon: 'grey',
    GP: 3,
    GW: 2,
    GF: 10,
    GA: 7,
    points: 11
  },
  {
    name: 'Bundle Africa',
    players: [
      {
        name: 'Yemi Otitoju',
        email: 'omoyemi@bundle.africa',
      },
      {
        name: 'Bayo Ogundele',
        email: 'adebayo@bundle.africa',
      },
    ],
    icon: 'bundle',
    GP: 3,
    GW: 3,
    GF: 11,
    GA: 2,
    points: 18,
  },
  {
    name: 'Credpal',
    players: [
      {
        name: 'Fagbuyi Damilare',
        email: 'damilare.f@credpal.com',
      },
    ],
    icon: 'credpal',
    GP: 0,
    GW: 0,
    GF: 0,
    GA: 0,
    points: 0
  },
  {
    name: 'Youverify',
    players: [
      {
        name: 'Emmanuel Agwu',
        email: 'emmanuel@youverify.co',
      },
      {
        name: 'Hakeem Akiode',
        email: 'hakeem@youverify.co',
      },
    ],
    icon: 'youverify',
    GP: 3,
    GW: 1,
    GF: 5,
    GA: 12,
    points: 4
  },
  {
    name: 'Sycamore',
    players: [
      {
        name: 'Kingsley Makinde',
        email: 'kingsley.makinde@sycamore.ng',
      },
      {
        name: 'Joseph Akinnitire',
        email: 'joseph.akinnitire@sycamore.ng',
      },
    ],
    icon: 'sycamore',
    GP: 3,
    GW: 1,
    GF: 6,
    GA: 10,
    points: 3
  },
];

const companyPlayers = [];
const leaderboard = companies;

companies.forEach(company =>{
  if(company?.players?.length){
      for(let i=0; i< company.players.length; i++){
    companyPlayers.push({
      ...company.players[i],
      company: company.name
    })
  }
  }
})

leaderboard.sort((a, b) => {
  if (a.points - b.points === 0) {
    return b.GF - a.GF;
  }
  else return b.points - a.points;
});

const players = companyPlayers;

export {leaderboard, players};

export default companies;
