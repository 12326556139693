<template>
  <div class="button-outer cursor-pointer">
    <div class="button-inner px-5 py-2 md:py-3 md:px-10">
      <p class="text-sm">{{text}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GradientButton',
  props: {
    text: String,
  }
}
</script>

<style lang="scss" scoped>
.button-outer {
  background: linear-gradient(to right, #37003C, #00FF87);
  position: relative;
  width: fit-content;
  padding: 1.5px;
  border-radius: 10px;
}

.button-inner {
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
}
</style>