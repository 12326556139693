<template>
  <div>
    <div class="flex pl-5 md:pl-20 py-8">
      <router-link to="/leaderboard">
        <gradient-button text="Leaderboard">
          <div class="ml-2">
            <trophy-small />
          </div>
        </gradient-button>
      </router-link>
      <div class="mr-5" />
      <router-link to="/matches">
        <gradient-button text="Fixtures">
          <div class="ml-2">
            <controller />
          </div>
        </gradient-button>
      </router-link>
    </div>
    <section class="w-full header pl-5 py-10 md:pl-20 md:py-20">
      <div>
        <p class="text-white text-2xl md:text-4xl">Fixtures</p>
      </div>
    </section>
    <section class="px-0 py-5 md:px-28 md:py-10">
      <div>
        <div class="flex flex-col items-center font-clash-display font-semibold px-5 md:px-10">
          <div class="flex w-full items-center" v-for="(item, index) in matchesData" :key="index">
            <div :class="index === 0 ? 'border-t border-b' : 'border-b'" class="flex items-center pl-0 md:pl-20 w-1/3 border-gray-200 py-4">
              <div>
                <component :is="item.player1.icon" />
              </div>
              <p class="ml-2 md:ml-5 text-xs md:text-base">{{item.player1.company}}</p>
            </div>
            <div class="flex justify-between w-1/3 bg-purple border border-purple py-4 px-5 text-white">
              <div class="flex flex-col items-center w-1/2 text-xs md:text-base">{{item.player1.goals}}</div>
              <div class="flex flex-col items-center w-1/2 text-xs md:text-base">{{item.player2.goals}}</div>
            </div>
            <div :class="index === 0 ? 'border-t border-b' : 'border-b'" class="flex items-center pl-4 md:pl-20 w-1/3 border-t border-b border-gray-200 py-4">
              <div>
                <component :is="item.player2.icon" />
              </div>
              <p class="ml-2 md:ml-5 text-xs md:text-base">{{item.player2.company}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import GradientButton from '@/components/GradientButton.vue';
import TrophySmall from '@/components/TrophySmall.vue';
import Controller from '@/components/Controller.vue';
import matches from '@/constants/matches';

export default {
  name: 'Matches',
  components: {
    GradientButton,
    TrophySmall,
    Controller
  },
  computed: {
    matchesData() {
      return matches
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(90deg, #37003C 15.15%, #00FF87 95.92%), url(../assets/images/ZapSmall.png);
  background-size: cover;
  background-position: right;
  background-blend-mode: multiply;
}
</style>
