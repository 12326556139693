<template>
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M14.3329 14.333V28.4355C14.3329 38.5965 22.472 47.0171 32.633 47.0939C35.0637 47.1108 37.4738 46.6466 39.7244 45.728C41.9749 44.8095 44.0216 43.4548 45.7463 41.7419C47.4711 40.029 48.84 37.9918 49.7741 35.7476C50.7082 33.5035 51.189 31.0967 51.189 28.6659V14.333C51.189 13.7899 50.9732 13.2691 50.5892 12.8851C50.2052 12.5011 49.6844 12.2854 49.1414 12.2854H16.3805C15.8374 12.2854 15.3166 12.5011 14.9326 12.8851C14.5486 13.2691 14.3329 13.7899 14.3329 14.333Z" :fill="color"/>
    <path d="M14.3329 14.333V28.4355C14.3329 38.5965 22.472 47.0171 32.633 47.0939C35.0637 47.1108 37.4738 46.6466 39.7244 45.728C41.9749 44.8095 44.0216 43.4548 45.7463 41.7419C47.4711 40.029 48.84 37.9918 49.7741 35.7476C50.7082 33.5035 51.189 31.0967 51.189 28.6659V14.333C51.189 13.7899 50.9732 13.2691 50.5892 12.8851C50.2053 12.5011 49.6844 12.2854 49.1414 12.2854H16.3805C15.8374 12.2854 15.3166 12.5011 14.9326 12.8851C14.5486 13.2691 14.3329 13.7899 14.3329 14.333Z" :stroke="color" stroke-width="4.09512" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.5707 57.3318H40.9511" :stroke="color" stroke-width="4.09512" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.7609 47.094V57.3318" :stroke="color" stroke-width="4.09512" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.7283 32.7611H53.2366C55.4087 32.7611 57.492 31.8982 59.0279 30.3623C60.5639 28.8263 61.4268 26.7431 61.4268 24.5709V20.4758C61.4268 19.9327 61.2111 19.4119 60.8271 19.0279C60.4431 18.6439 59.9223 18.4282 59.3792 18.4282H51.189" :stroke="color" stroke-width="4.09512" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8449 32.7611H12.2598C10.0877 32.7611 8.00444 31.8982 6.46847 30.3623C4.93251 28.8263 4.06961 26.7431 4.06961 24.5709V20.4758C4.06961 19.9327 4.28533 19.4119 4.66933 19.0279C5.05332 18.6439 5.57412 18.4282 6.11717 18.4282H14.3074" :stroke="color" stroke-width="4.09512" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Trophy',
  props: {
    color: {
      type: String,
      default: '#FDAF81'
    }
  }
}
</script>
