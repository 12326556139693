<template>
  <svg width="40" height="40" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path d="M20.8987 66.3838C25.6512 66.3838 29.504 62.5311 29.504 57.7785C29.504 53.0259 25.6512 49.1732 20.8987 49.1732C16.1461 49.1732 12.2933 53.0259 12.2933 57.7785C12.2933 62.5311 16.1461 66.3838 20.8987 66.3838Z" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.2933 22.1279L24.5866 34.4212" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.5866 22.1279L12.2933 34.4212" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M54.0906 51.6318L66.3839 63.9251" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M66.3839 51.6318L54.0906 63.9251" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M41.7972 24.5866V12.2933H54.0905" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M41.7973 12.2933L46.7146 17.2107C59.0079 29.504 49.1733 44.2559 39.3386 46.7146" stroke="black" stroke-width="4.91732" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Strategy'
}
</script>
