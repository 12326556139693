<template>
  <div>
    <div class="flex pl-5 md:pl-20 py-8">
      <router-link to="/leaderboard">
        <gradient-button text="Leaderboard">
          <div class="ml-2">
            <trophy-small />
          </div>
        </gradient-button>
      </router-link>
      <div class="mr-5" />
      <router-link to="/matches">
        <gradient-button text="Fixtures">
          <div class="ml-2">
            <controller />
          </div>
        </gradient-button>
      </router-link>
    </div>
    <section class="w-full header pl-5 py-10 md:pl-20 md:py-20">
      <div>
        <p class="text-white text-2xl md:text-4xl">Streaming</p>
      </div>
    </section>
    <section class="px-4 py-5 md:px-28 md:py-10">
      <div class="mb-8">
        <p class="text-xl">Live Games</p>
        <p class="font-clash-display">Use the tabs to switch between ongoing games.</p>
      </div>
      <div class="flex justify-between items-center px-20 mb-5">
        <div
          v-for="(game, index) in games" :key="index"
          :class="selectedGame.title === game.title ? 'bg-purple text-white' : 'bg-white text-purple'"
          class="w-1/2 flex flex-col items-center py-6 cursor-pointer"
          @click="() => toggleGame(game)">
          <p>{{game.title}}</p>
        </div>
      </div>
      <div class="border-2 border-primary">
        <twitch-player
          :channel="selectedGame.channel"
          width="100%"
          height="600"
        ></twitch-player>
      </div>
      <div class="mt-5">
        <div class="flex flex-col items-center font-clash-display font-semibold px-5 md:px-10">
          <div class="flex w-full items-center">
            <div class="flex items-center pl-0 md:pl-20 w-1/3 border-gray-200 py-4 border-t border-b">
              <div>
                <component :is="selectedGame.matches[0].icon" />
              </div>
              <p class="ml-2 md:ml-5 text-xs md:text-base">{{selectedGame.matches[0].company}}</p>
            </div>
            <div class="flex justify-center w-1/3 py-4 px-5 text-gray-700">
              <p>VS</p>
            </div>
            <div class="flex items-center pl-4 md:pl-20 w-1/3 border-t border-b border-gray-200 py-4">
              <div>
                <component :is="selectedGame.matches[1].icon" />
              </div>
              <p class="ml-2 md:ml-5 text-xs md:text-base">{{selectedGame.matches[1].company}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TwitchPlayer from '@/components/TwitchPlayer.vue';
import GradientButton from '@/components/GradientButton.vue';
import TrophySmall from '@/components/TrophySmall.vue';
import Controller from '@/components/Controller.vue';

const allGames = [
  {
    title: 'Game 1',
    channel: 'thedaniellamarr',
    matches: [
      {
        company: 'Touch and Pay',
        playerName: '',
        icon: 'tap'
      },
      {
        company: 'Periculum',
        playerName: '',
        icon: 'periculum'
      }
    ]
  },
  {
    title: 'Game 2',
    channel: 'sycamoreng',
    matches: [
      {
        company: 'Flutterwave',
        playerName: '',
        icon: 'flutterwave'
      },
      {
        company: 'Credpal',
        playerName: '',
        icon: 'credpal'
      }
    ]
  }
];

export default {
  name: 'Stream',
  data() {
    return {
      selectedGame: null
    }
  },
  components: {
    TwitchPlayer,
    GradientButton,
    TrophySmall,
    Controller
  },
  computed: {
    games() {
      return allGames;
    }
  },
  methods: {
    toggleGame(game) {
      this.selectedGame = game;
    }
  },
  created() {
    this.selectedGame = this.games[0]
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(90deg, #37003C 15.15%, #00FF87 95.92%), url(../assets/images/ZapSmall.png);
  background-size: cover;
  background-position: right;
  background-blend-mode: multiply;
}
</style>
