<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2927 35.7073L35.7073 12.2927" stroke="#010141" stroke-width="2.92683" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.6829 12.2927H35.7073V31.3171" stroke="#010141" stroke-width="2.92683" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowUpRight',
  props: {
    size: {
      type: Number,
      default: 48
    }
  }
}
</script>
