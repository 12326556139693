<template>
  <svg width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.7423 17.8158C20.0911 15.9454 20.8498 13.7065 20.8498 11.3826C20.8498 11.2976 20.8498 11.2126 20.8498 11.1275C20.7656 8.26517 19.6134 5.60121 17.5902 3.58907C15.567 1.60526 12.8975 0.5 10.0593 0.5C7.44595 0.5 4.94503 1.46356 2.97801 3.19231C2.5565 3.56073 2.5284 4.21255 2.92181 4.60931L4.60784 6.30972L1.9664 8.97368C1.60109 9.34212 1.60109 9.96557 1.9664 10.334L2.83751 11.2126C1.46059 13.0546 0.72998 15.3219 0.72998 17.6458C0.72998 20.5081 1.82589 23.2004 3.79292 25.2409C5.75994 27.2814 8.42949 28.4433 11.2395 28.5C11.3238 28.5 11.4081 28.5 11.5205 28.5C14.1339 28.5 16.6348 27.5364 18.6018 25.8077C19.0233 25.4393 19.0514 24.7874 18.658 24.3907L16.972 22.6903L19.6134 20.0263C19.9787 19.6579 19.9787 19.0344 19.6134 18.666L18.7423 17.8158ZM14.9207 23.3988L16.5224 25.0142C15.0612 26.0344 13.319 26.5729 11.5205 26.5729C11.4643 26.5729 11.38 26.5729 11.2957 26.5729C8.96338 26.5162 6.79965 25.5526 5.16984 23.8806C3.54002 22.2085 2.64081 19.9696 2.64081 17.6174C2.64081 15.8036 3.17471 14.0466 4.18633 12.5729L11.5767 20.0263L12.4759 20.9332L12.9817 21.4433L14.3306 20.083L13.8247 19.5729L4.07393 9.71053L4.01772 9.65383L6.65916 6.98987C7.02445 6.62146 7.02445 5.99797 6.65916 5.62956L5.05745 4.01417C6.51867 2.99393 8.2609 2.45546 10.0593 2.45546C12.3916 2.45546 14.5835 3.36235 16.2695 5.00608C17.9274 6.64982 18.8828 8.83199 18.939 11.1842C18.939 11.2409 18.939 11.3259 18.939 11.3826C18.939 13.1964 18.4051 14.9534 17.3935 16.4271L13.3752 12.4028L9.0758 8.06679L8.56998 7.55666L7.19308 8.91701L12.8412 14.6134L17.5621 19.3745L14.9207 22.0385C14.5554 22.4069 14.5554 23.002 14.9207 23.3988Z" fill="#009E74"/>
  </svg>
</template>

<script>
export default {
  name: 'Okra'
}
</script>
