<template>
  <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7953 21.358C10.7559 20.981 10.7166 20.6254 10.67 20.196C11.127 20.1685 11.4574 20.128 11.7855 20.128C13.7921 20.122 15.7964 20.085 17.803 20.1339C22.1145 20.2413 25.6959 17.6692 27.1001 14.2476C29.1199 9.33364 26.3891 4.15838 21.6242 2.41063C20.3203 1.92761 18.9406 1.68117 17.5501 1.68289C12.8568 1.68647 8.16591 1.68289 3.47262 1.68289C3.20658 1.68289 2.94412 1.67096 2.6757 1.69244C1.94558 1.74851 1.85014 1.84634 1.81435 2.53947C1.8048 2.75183 1.81435 2.96299 1.81435 3.17654V28.485H0.636849C0.400634 27.6177 0.350528 1.88093 0.580778 0.647366C0.945838 0.604417 1.34788 0.518521 1.74515 0.518521C7.19361 0.51017 12.6421 0.559083 18.0929 0.500626C23.0618 0.448134 27.3948 3.70385 28.6486 7.94618C29.8846 12.1336 28.8395 15.9071 25.3679 18.9338C23.7048 20.3892 21.6171 21.1718 19.4064 21.3126C16.5969 21.4975 13.7516 21.3591 10.7953 21.3591V21.358Z" fill="#444854"/>
    <path d="M3.22339 28.4802V2.9892C3.61112 2.95818 3.938 2.91165 4.26608 2.91165C8.95936 2.90569 13.6479 2.90211 18.3412 2.91165C21.6303 2.91762 24.843 5.27261 25.9537 8.04396C26.5388 9.5005 26.6539 11.1035 26.283 12.6287C25.6268 15.4096 23.9018 17.5117 20.893 18.4565C20.1915 18.6784 19.4375 18.8383 18.6991 18.8538C16.3142 18.9015 13.9306 18.8777 11.5494 18.8717C11.1819 18.8717 10.7525 18.9337 10.6666 18.4303C10.5986 18.0139 10.861 17.6811 11.3466 17.6381C11.7558 17.6011 12.1781 17.6226 12.5921 17.6226C14.4818 17.6202 16.3775 17.582 18.2672 17.6286C21.1901 17.7002 23.7491 15.7818 24.5782 13.4912C26.0337 9.46006 24.4207 6.56464 21.0099 4.75246C20.3573 4.40649 19.5175 4.22754 18.7599 4.21799C14.2205 4.1655 9.68113 4.18698 5.13816 4.18459C4.99023 4.18459 4.8423 4.21799 4.56075 4.25498C4.53092 4.69162 4.47485 5.13542 4.47485 5.57444C4.46889 12.6919 4.47127 19.807 4.47127 26.9233C4.47127 27.1726 4.48201 27.4184 4.46889 27.6689C4.43071 28.4909 4.34481 28.5494 3.22339 28.4778V28.4802Z" fill="#444854"/>
    <path d="M7.02186 28.4801H5.76562V5.52192C6.18676 5.48136 6.58522 5.40858 6.98607 5.40858C10.6558 5.39904 14.3302 5.48851 17.9975 5.37756C21.0671 5.2857 23.6166 7.67649 23.9423 10.2999C24.2286 12.5964 22.9401 15.4418 19.954 16.1719C19.1553 16.373 18.3375 16.489 17.5143 16.5179C15.6998 16.5715 13.8793 16.5346 12.0647 16.5346H10.9791C10.4673 15.4632 10.5889 15.258 11.6805 15.2509C13.7242 15.2425 15.7678 15.1984 17.8114 15.2604C20.0638 15.3284 21.8485 13.9994 22.3985 12.2672C22.9413 10.5576 22.4486 8.81461 20.7188 7.47606C19.929 6.86286 19.0032 6.59562 17.9999 6.58966C14.7072 6.57415 11.4169 6.58011 8.1242 6.58966C7.79612 6.58966 7.46804 6.66362 7.02663 6.71612C7.02186 14.0018 7.02186 21.2111 7.02186 28.4825V28.4801Z" fill="#444854"/>
    <path d="M10.7523 13.8431C10.7034 12.8624 10.769 12.7515 11.6315 12.7455C13.6382 12.7264 15.6424 12.7419 17.6491 12.736C18.0272 12.736 18.4126 12.7384 18.7776 12.668C19.33 12.5606 19.738 12.2624 19.8072 11.6921C19.8502 11.3426 19.8895 10.9847 19.8692 10.6339C19.8036 9.5256 19.3085 9.09016 18.0583 9.07465C16.1292 9.05556 14.1977 9.06868 12.2686 9.06868H9.79908C9.76568 9.60912 9.71318 10.0565 9.71318 10.5015C9.70603 15.9917 9.7096 21.4807 9.7096 26.9674C9.7096 27.2167 9.72273 27.4625 9.70603 27.713C9.65353 28.492 9.5223 28.5719 8.45337 28.4419V7.85778C8.81485 7.82915 9.10952 7.78978 9.40062 7.78978C12.2388 7.7862 15.0757 7.80171 17.9151 7.78382C19.2894 7.77427 20.2701 8.30874 20.9465 9.42301C21.7757 10.7926 21.1708 13.0474 19.7273 13.793C19.4933 13.9074 19.2361 13.9662 18.9757 13.9648C16.3296 13.9743 13.6823 13.9684 11.0315 13.9624C10.9623 13.96 10.8943 13.9087 10.7523 13.8431V13.8431Z" fill="#444854"/>
  </svg>
</template>

<script>
export default {
  name: 'Patricia'
}
</script>
