<template>
  <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 7V13.8875C7 18.85 10.975 22.9625 15.9375 23C17.1246 23.0082 18.3017 22.7815 19.4008 22.3329C20.5 21.8843 21.4995 21.2227 22.3419 20.3862C23.1843 19.5496 23.8528 18.5547 24.309 17.4586C24.7652 16.3626 25 15.1872 25 14V7C25 6.73478 24.8946 6.48043 24.7071 6.29289C24.5196 6.10536 24.2652 6 24 6H8C7.73478 6 7.48043 6.10536 7.29289 6.29289C7.10536 6.48043 7 6.73478 7 7Z" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 28H20" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 23V28" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.775 16H26C27.0609 16 28.0783 15.5786 28.8285 14.8284C29.5786 14.0783 30 13.0609 30 12V10C30 9.73478 29.8947 9.48043 29.7071 9.29289C29.5196 9.10536 29.2652 9 29 9H25" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.25005 16H5.98755C4.92668 16 3.90927 15.5786 3.15912 14.8284C2.40898 14.0783 1.98755 13.0609 1.98755 12V10C1.98755 9.73478 2.09291 9.48043 2.28044 9.29289C2.46798 9.10536 2.72233 9 2.98755 9H6.98755" stroke="#010141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TrophySmall'
}
</script>
