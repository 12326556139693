import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Leaderboard from '../views/Leaderboard.vue'
// import Players from '../views/Players.vue'
import Matches from '../views/Matches.vue'
import Stream from '../views/Stream.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard
  },
  // {
  //   path: '/players',
  //   name: 'Players',
  //   component: Players
  // },
  {
    path: '/matches',
    name: 'Matches',
    component: Matches
  },
  {
    path: '/watch',
    name: 'Stream',
    component: Stream
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
