<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 0H6.15374L6.13871 0.0272807L5.05583 1.99984H14C20.6253 1.99984 25.9996 7.37247 25.9996 14C25.9996 20.6275 20.627 26.0002 14 26.0002C7.37302 26.0002 1.99984 20.627 1.99984 14C1.9993 12.7463 2.19562 11.5004 2.58164 10.3076H4.87266C3.24858 14.321 4.43669 18.9243 7.80019 21.6504C11.1637 24.3764 15.9133 24.5855 19.5033 22.1655C23.0933 19.7455 24.6813 15.2644 23.4161 11.1239C22.1509 6.98336 18.3295 4.15512 14 4.15501H3.8733L2.77595 6.1543H14C17.5259 6.15502 20.6181 8.50803 21.5587 11.9061C22.4993 15.3042 21.0572 18.9124 18.0336 20.726C15.01 22.5397 11.1478 22.1133 8.59272 19.6836C6.03763 17.2539 5.41749 13.4181 7.07683 10.3071H9.67239C7.93991 12.3409 7.85611 15.3062 9.47096 17.4346C11.0858 19.563 13.9642 20.281 16.3894 19.1602C18.8147 18.0395 20.1333 15.3821 19.5588 12.7729C18.9844 10.1637 16.6717 8.30583 14 8.30725H1.59341L0.495506 10.3076C0.166866 11.5109 0.000227345 12.7527 0 14C0 21.7316 6.26788 28 14 28C21.7321 28 28 21.7316 28 14C28 6.26843 21.7316 0 14 0ZM14 10.3076C16.0392 10.3076 17.6924 11.9608 17.6924 14C17.6924 16.0392 16.0392 17.6924 14 17.6924C11.9608 17.6924 10.3076 16.0392 10.3076 14C10.3076 11.9608 11.9608 10.3076 14 10.3076Z" fill="#0066F5"/>
  </svg>
</template>

<script>
export default {
  name: 'Cowryise'
}
</script>
