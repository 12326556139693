<template>
  <svg width="24" height="24" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45447 21.7812H36.5065" stroke="black" stroke-width="2.6411" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.9806 7.48096V36.081" stroke="black" stroke-width="2.6411" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Plus'
}
</script>
