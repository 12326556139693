<template>
  <div>
    <div class="flex pl-5 md:pl-20 py-8">
      <router-link to="/leaderboard">
        <gradient-button text="Leaderboard">
          <div class="ml-2">
            <trophy-small />
          </div>
        </gradient-button>
      </router-link>
      <div class="mr-5" />
      <router-link to="/matches">
        <gradient-button text="Fixtures">
          <div class="ml-2">
            <controller />
          </div>
        </gradient-button>
      </router-link>
    </div>
    <section class="w-full header pl-5 py-10 md:pl-20 md:py-20">
      <div>
        <p class="text-white text-2xl md:text-4xl">Leaderboard({{teams.length}})</p>
        <p class="font-clash-display text-white text-xs md:text-sm">
          The top 8 teams would be going through to the finals
        </p>
      </div>
    </section>
    <section class="px-0 py-0 pb-5 md:px-28 md:py-10 md:pb-5 overflow-scroll">
      <table class="font-clash-display text-sm md:text-base">
        <thead>
          <th>
            <div class="flex pl-7">
              Team
            </div>
          </th>
          <th>Games Played</th>
          <th>Games Won</th>
          <th>Goals Scored</th>
          <th>Goals Conceeded</th>
          <th>Points</th>
        </thead>
        <tbody>
          <tr v-for="(team, index) in teams" :key="index" :class="index === 7 ? 'border-b-2 border-red-200' : 'border-none'">
            <td class="font-semibold">
              <div class="flex items-center pl-7">
                <p>{{index + 1}}</p>
                <div class="w-16 ml-5">
                  <component :is="team.icon" />
                </div>
                <p class="text-xs md:text-base">{{team.name}}</p>
                <div class="bg-green-400 text-white rounded-sm text-xs px-1 py-1 ml-2" v-if="index >= 0 && index < 8">Finalist</div>
                <div class="bg-red-400 text-white rounded-sm text-xs px-1 py-1 ml-2" v-else-if="team.GP === 0">Forfeited</div>
                <div class="bg-yellow-400 text-white rounded-sm text-xs px-1 py-1 ml-2" v-else-if="index > 7">Eliminated</div>
              </div>
            </td>
            <td>{{team.GP}}</td>
            <td>{{team.GW}}</td>
            <td>{{team.GF}}</td>
            <td>{{team.GA}}</td>
            <td>{{team.points}}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import GradientButton from '@/components/GradientButton.vue';
import TrophySmall from '@/components/TrophySmall.vue';
import Controller from '@/components/Controller.vue';
import {leaderboard} from '@/constants/companies'

export default {
  name: 'Leaderboard',
  components: {
    GradientButton,
    TrophySmall,
    Controller
  },
  computed: {
    teams() {
      const sortedCompanies = leaderboard;
      return sortedCompanies;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(90deg, #37003C 15.15%, #00FF87 95.92%), url(../assets/images/ZapSmall.png);
  background-size: cover;
  background-position: right;
  background-blend-mode: multiply;
}
table {
  width: 100%;

  thead {
    th {
      padding: 30px 20px 30px 20px;
      background-color: #000;
      color: #fff;
    }
  }

  tbody {
    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
    td {
      text-align: center;
      padding: 30px 20px 30px 20px;
    }
  }
}
</style>
