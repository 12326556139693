<template>
  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1806 1.29224C14.1543 0.73161 14.9768 0.211573 15.0084 0.136443C15.0402 0.0614859 15.1663 0 15.2889 0C15.4939 0 17.3243 1.02557 18.8801 2.0121L19.5278 2.42281V4.64459C19.5278 6.41593 19.4749 6.91006 19.2668 7.08277C18.9956 7.30782 18.8856 7.3731 16.7077 8.60264L15.3894 9.34704L14.3065 8.70869C13.711 8.35756 12.9818 7.93286 12.6861 7.76481C11.2999 6.97673 11.4103 7.24599 11.4103 4.65478V2.31141L13.1806 1.29224Z" fill="#0054A6"/>
    <path d="M18.3622 10.1006C19.336 9.53996 20.1584 9.01992 20.19 8.94479C20.2218 8.86984 20.3479 8.80835 20.4705 8.80835C20.6755 8.80835 22.5059 9.83392 24.0617 10.8205L24.7094 11.2312V13.4529C24.7094 15.2243 24.6566 15.7184 24.4484 15.8911C24.1773 16.1162 24.0673 16.1815 21.8894 17.411L20.571 18.1554L19.4881 17.517C18.8926 17.1659 18.1634 16.7412 17.8677 16.5732C16.4816 15.7851 16.5919 16.0543 16.5919 13.4631V11.1198L18.3622 10.1006Z" fill="#0071BC"/>
    <path d="M12.4902 19.9452C13.4639 19.3846 14.2864 18.8645 14.318 18.7894C14.3498 18.7144 14.4758 18.653 14.5985 18.653C14.8035 18.653 16.6339 19.6785 18.1897 20.6651L18.8373 21.0758V23.2975C18.8373 25.0689 18.7845 25.563 18.5764 25.7357C18.3052 25.9608 18.1952 26.0261 16.0173 27.2556L14.699 28L13.6161 27.3616C13.0206 27.0105 12.2914 26.5858 11.9957 26.4178C10.6095 25.6297 10.7199 25.8989 10.7199 23.3077V20.9644L12.4902 19.9452Z" fill="#008FD4"/>
    <path d="M1.95451 19.9452C2.92827 19.3846 3.75072 18.8645 3.78233 18.7894C3.81411 18.7144 3.94019 18.653 4.06282 18.653C4.26783 18.653 6.09823 19.6785 7.65403 20.6651L8.3017 21.0758V23.2975C8.3017 25.0689 8.24885 25.563 8.04073 25.7357C7.76957 25.9608 7.65956 26.0261 5.48165 27.2556L4.16333 28L3.08043 27.3616C2.48491 27.0105 1.75572 26.5858 1.46003 26.4178C0.073843 25.6297 0.184211 25.8989 0.184211 23.3077V20.9644L1.95451 19.9452Z" fill="#00AEEF"/>
  </svg>
</template>

<script>
export default {
  name: 'Arca'
}
</script>
