<template>
  <svg width="78" height="29" viewBox="0 0 78 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M67.4375 27.5179L75.4974 22.1447C76.4704 21.4828 77.0546 20.3925 77.0546 19.2245V3.57212C77.0546 1.62532 75.4974 0.0678711 73.5505 0.0678711H3.50425C1.55745 0.0678711 0 1.62532 0 3.57212V19.497V21.0544V24.5587C0 26.5055 1.55745 28.063 3.50425 28.063H65.4905C66.1914 28.1019 66.8924 27.9072 67.4375 27.5179Z" fill="#46B2C8"/>
    <path d="M13.1605 7.07617L10.474 15.7589V21.0932H9.11118V15.7589L6.58032 7.07617H7.98203L9.77308 13.306L11.7199 7.07617H13.1605Z" fill="#C7E8EF"/>
    <path d="M20.2857 8.90621V19.2632C20.2857 20.1977 19.5849 20.9764 18.6504 21.0932C18.5725 21.0932 18.5336 21.0932 18.4557 21.0932H16.0416C15.9638 21.0932 15.8859 21.0932 15.847 21.0932C14.9125 20.9764 14.2117 20.1977 14.2117 19.2632V8.90621C14.2117 7.89384 15.0293 7.07617 16.0416 7.07617H18.4557C19.468 7.07617 20.2857 7.89384 20.2857 8.90621ZM18.9229 8.90621C18.9229 8.63365 18.6893 8.43895 18.4557 8.43895H16.0416C15.7691 8.43895 15.5744 8.67258 15.5744 8.90621V19.2632C15.5744 19.5358 15.808 19.7304 16.0416 19.7304H18.4557C18.7283 19.7304 18.9229 19.4968 18.9229 19.2632V8.90621Z" fill="#C7E8EF"/>
    <path d="M27.7615 19.4579C27.7615 20.2755 27.1385 20.9764 26.3208 21.0932C26.2429 21.0932 26.204 21.0932 26.1261 21.0932H23.2838C23.2059 21.0932 23.167 21.0932 23.0891 21.0932C22.2715 20.9764 21.6484 20.3145 21.6484 19.4579V7.07617H23.0112V19.4579C23.0112 19.6136 23.1281 19.7304 23.2838 19.7304H26.1261C26.2819 19.7304 26.3987 19.6136 26.3987 19.4579V7.07617H27.7615V19.4579Z" fill="#C7E8EF"/>
    <path d="M33.6408 19.7304C33.485 20.5092 32.8231 21.0543 32.0443 21.0543C31.2657 21.0543 30.6037 20.5092 30.448 19.7304V19.6915L28.9294 7.07617H30.2922L31.7718 19.4968C31.8107 19.6526 31.9276 19.6915 32.0443 19.6915C32.1612 19.6915 32.278 19.6526 32.3169 19.4968L33.8354 7.07617H35.1982L33.6408 19.7304Z" fill="white"/>
    <path d="M37.7292 8.43895V14.9802H40.2211V16.343H37.7292V19.7304H41.8175V21.0932H36.3665V7.07617H41.7786V8.43895H37.7292Z" fill="white"/>
    <path d="M49.916 21.0932H48.2807L45.049 16.2262C44.8932 16.2651 44.7375 16.3041 44.5817 16.3041V21.0932H43.219V8.90621C43.219 7.89384 44.0366 7.07617 45.049 7.07617H47.463C48.4754 7.07617 49.293 7.89384 49.293 8.90621V10.9309C49.293 13.0334 48.086 14.8634 46.3338 15.759L49.916 21.0932ZM44.5817 14.9413C46.4896 14.6298 47.9692 12.9556 47.9692 10.9309V8.90621C47.9692 8.63365 47.7356 8.43895 47.5019 8.43895H45.0879C44.8153 8.43895 44.6207 8.67258 44.6207 8.90621L44.5817 14.9413Z" fill="white"/>
    <path d="M51.2791 7.07617H52.6418V21.0932H51.2791V7.07617Z" fill="white"/>
    <path d="M55.6008 8.43895V14.9802H58.0929V16.343H55.6008V21.0932H54.238V7.07617H59.65V8.43895H55.6008Z" fill="white"/>
    <path d="M66.97 7.07617L64.2836 15.759V21.0932H62.9208V15.759L60.3901 7.07617H61.7915L63.5826 13.306L65.5296 7.07617H66.97Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'Youverify'
}
</script>
