<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.799316 9.95724L5.48477 5.22879L10.1702 0.5L14.6127 0.594857L19.0552 0.689381L19.1525 4.60945C19.2732 9.47576 19.3534 9.39079 14.6223 9.39079C12.3944 9.39079 11.0277 9.53582 10.7633 9.80027C10.492 10.0716 10.3538 11.5443 10.3538 14.168C10.3538 16.7918 10.2156 18.2645 9.94432 18.5358C9.67236 18.8078 8.18767 18.9453 5.52026 18.9453C1.90048 18.9453 1.4712 18.8804 1.15249 18.2853C0.958332 17.9223 0.799316 15.8998 0.799316 13.7913V9.95724Z" fill="black"/>
    <path d="M19.5671 14.7481C19.5671 12.2039 19.7063 10.753 19.9765 10.4827C20.4065 10.0528 27.2134 9.88626 28.2405 10.2804C28.6947 10.4547 28.7803 11.1973 28.7803 14.9679V19.4479L24.2638 23.974L19.7472 28.4998H15.4037C10.2265 28.4998 10.3538 28.6073 10.3538 24.2344C10.3538 19.9512 10.3361 19.969 14.6311 19.969C19.5073 19.969 19.5671 19.9058 19.5671 14.7481Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'Grey'
}
</script>
