<template>
  <button class="text-sm md:text-xl rounded-xl px-5 py-3 md:px-10 md:py-5 flex items-center">
    <p class="mr-3">{{text}}</p>
    <arrow-up-right :size="23" />
  </button>
</template>

<script>
import ArrowUpRight from './ArrowUpRight.vue'
export default {
  name: 'CButton',
  components: {
    ArrowUpRight
  },
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
  button {
    background: linear-gradient(270.47deg, #FFCC00 0.41%, #DCE702 152.97%);
  }
</style>
